'use client';

import { ConfirmProvider as MuiConfirmProvider } from 'material-ui-confirm';

type Props = {
  children: React.ReactNode;
};

export default function ConfirmProvider({ children }: Props) {
  return <MuiConfirmProvider>{children}</MuiConfirmProvider>;
}
