'use client';

import axios from 'axios';
import { useInterval } from 'usehooks-ts';
import { HOST_API } from '@/src/config-global';
import { useMemo, useState, useEffect, useReducer, useCallback } from 'react';

import { endpoints } from 'src/utils/axios';

import { AuthContext } from './auth-context';
import { isValidToken, setAccessToken, setRefreshToken } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  UPDATE = 'UPDATE',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.UPDATE]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';
const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [popup, setPopup] = useState<Window | null>(null);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setAccessToken(accessToken);

        const res = await axios.get(endpoints.auth.whoami, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const user = res.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              accessToken,
            },
          },
        });
      } else if (refreshToken && isValidToken(refreshToken)) {
        const { access, refresh } = (
          await axios.post(endpoints.auth.refresh, {
            refresh: refreshToken,
          })
        ).data;
        setAccessToken(access);
        setRefreshToken(refresh);

        const res = await axios.get(endpoints.auth.whoami, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        const user = res.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              accessToken: access,
            },
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useInterval(
    () => {
      popup?.postMessage({ message: 'ping' }, '*');
    },
    popup && !popup.closed ? 1000 : null
  );

  const openOAuthPopup = useCallback((url: string) => {
    const width = 500;
    const height = 800;

    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top},resizable=no`;

    const _popup = window.open('', 'rt-auth', windowFeatures);
    const form = document.createElement('form') as HTMLFormElement;
    form.method = 'post';
    form.action = url;
    form.target = 'rt-auth';
    document.body.appendChild(form);
    form.submit();
    setPopup(_popup);
  }, []);

  const getDataFromPopup = async (e: any) => {
    if (e.origin === HOST_API) {
      const { access, refresh } = e.data;
      if (access === undefined || refresh === undefined) {
        return;
      }

      setAccessToken(access);
      setRefreshToken(refresh);

      const user = (
        await axios.get(endpoints.auth.whoami, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        })
      ).data;

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...user,
            accessToken: access,
          },
        },
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', getDataFromPopup, false);

    return () => {
      window.removeEventListener('message', getDataFromPopup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // SOCIAL LOGIN
  const loginWithGoogle = useCallback(async () => {
    openOAuthPopup(`${HOST_API}${endpoints.auth.social.google}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loginWithNaver = useCallback(async () => {
    openOAuthPopup(`${HOST_API}${endpoints.auth.social.naver}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loginWithKakao = useCallback(async () => {
    openOAuthPopup(`${HOST_API}${endpoints.auth.social.kakao}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loginWithApple = useCallback(async () => {
    openOAuthPopup(`${HOST_API}${endpoints.auth.social.apple}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      username: email,
      password,
    };

    const res = await axios.post(endpoints.auth.login, data, {
      withCredentials: true,
    });
    const { refresh, access } = res.data;
    setAccessToken(access);
    setRefreshToken(refresh);

    const user = (
      await axios.get(endpoints.auth.whoami, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
    ).data;

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken: access,
        },
      },
    });
  }, []);

  // LOGIN with MFA
  const mfaLogin = useCallback(async (email: string, password: string, code: string) => {
    const data = {
      username: email,
      password,
      code,
    };

    const res = await axios.post(endpoints.auth.mfaLogin, data, {
      withCredentials: true,
    });
    const { refresh, access } = res.data;
    setAccessToken(access);
    setRefreshToken(refresh);

    const user = (
      await axios.get(endpoints.auth.whoami, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
    ).data;

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken: access,
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      await axios.post(endpoints.auth.register, data);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: null,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setAccessToken(null);
    setRefreshToken(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // UPDATE
  const update = useCallback(async (user: any) => {
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

    dispatch({
      type: Types.UPDATE,
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      mfaLogin,
      register,
      update,
      logout,
      loginWithGoogle,
      loginWithNaver,
      loginWithKakao,
      loginWithApple,
    }),
    [
      login,
      mfaLogin,
      logout,
      register,
      state.user,
      status,
      update,
      loginWithGoogle,
      loginWithNaver,
      loginWithKakao,
      loginWithApple,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
